import { useRouter } from 'next/router'

import * as Money from '@mondough/money'
import { Avatar, PlainCard, Text } from '@mondough/monzo-ui'

import { useAppContext } from '../AppContext'
import { Button, Loading, StatefulLayout } from '../components'
import { LayoutElementProps } from '../components/Layout/Layout'
import { copy } from '../components/copy'
import usePageTracking from '../enhancers/usePageTracking'
import { paymentCompleted } from '../utils/payment'
import { getAvatarSrc } from '../utils/utils'
import NotFoundPage from './404'
import styles from './home.module.scss'

export default function InitialPage(props: LayoutElementProps) {
  const { payment, status, code, errors } = useAppContext()
  const router = useRouter()

  usePageTracking({
    event: 'pay-anyone.web-page.home',
    payment,
  })

  if (errors?.notFound) {
    return <NotFoundPage />
  }

  if (!payment) {
    return <Loading />
  }

  const payerName = payment.sender_full_name
  const amount = Money.fromMinorUnits(
    payment.currency as string,
    payment.amount as number,
  )
  const notes = payment.notes

  const isCtaDisabled = paymentCompleted(status)

  return (
    <StatefulLayout {...props}>
      <PlainCard className={styles.wrapper}>
        <Avatar
          size="extra-large"
          name={payerName}
          src={getAvatarSrc(payment.sender_user_id)}
        />
        <Text is="h1" size="large" align="center" bold className={styles.title}>
          {payerName}
          {copy.pages.home.card.title}
          {amount.format()}
        </Text>
        {notes && <Text className={styles.notes}>{notes}</Text>}
        <Button
          theme="primary"
          fullWidth
          onClick={async () => {
            await router.push(`/claim#${code}`)
          }}
          disabled={isCtaDisabled}
        >
          {copy.pages.home.card.cta}
        </Button>
        <Text className={styles['helper-text']}>
          {copy.statusHelpers[status]}
        </Text>
      </PlainCard>
    </StatefulLayout>
  )
}
